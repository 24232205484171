import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GothamBold from "../../fonts/Gotham-Bold.woff2";
import {useLocation} from "react-router-dom";

const dateLabel = ["Saturday", "3rd February", "2024"];
const locationLabel = ["at O2 Kentish Town ", "London"];
// const eventName = "SAMAGGI FEST 2024"

export const MenuHeader: React.FC<{ withEventName?: boolean, withDate?: boolean, extraPad?: boolean }> = (props) => {

    const [eventName, setEventName] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [minor, setMinor] = useState("");
    const [textColour, setTextColour] = useState("");

    const location = useLocation();

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (eventID !== "") {
            const allEventData = JSON.parse(localStorage.getItem("eventData"));
            const eventData = allEventData[eventID];

            if (eventData !== undefined) {
                setEventName(eventData["web-main-title"])
                setSubtitle(eventData["web-main-subtitle"])
                setMinor(eventData["web-main-minor"])
                setTextColour(eventData["web-title-colour-css"])
            }
        }
    }, []);

    return <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        width="90%"
        maxWidth="90%"
        marginLeft = {props.extraPad ? "20px" : "0px"}
        marginRight = {props.extraPad ? "20px" : "0px"}
    >
        {props.withEventName && (
            <Typography component="h4" variant="h4" style={{color: "white", width: "full", fontFamily: GothamBold, backgroundImage: textColour, WebkitTextFillColor: "transparent", WebkitBackgroundClip: "text"}}>
                {eventName}
            </Typography>
        )}
        {props.withDate && (
            <Typography variant="subtitle1" style={{color: "rgb(41,60,126)", width: "full", marginTop: "5px", fontWeight: "bold", fontFamily: GothamBold}}>
                {subtitle}
            </Typography>
        )}

        <Typography variant="subtitle1" style={{color: "rgb(41,60,126)", fontFamily: GothamBold}}>
            {minor}
        </Typography>
    </Box>;

}