import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
  Divider,
  Grid, MenuItem
} from "@material-ui/core";
import axios from "axios";
import { months, years } from "./Dropdown";
import {MenuHeader} from "../Components/MenuHeader";
import {TextField} from "formik-mui";
import {NormalButton} from "../Components/NormalButton";
import {SamaggiField} from "../Components/SamaggiField";
import {BackButton} from "../Components/BackButton";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 40,
    padding: theme.spacing(1.5),
    backgroundColor: "white",
    "&:disabled": {
      backgroundColor: "rgba(57, 57, 57, 0.14)",
    },
  },
  paper: {
    background:
        "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    display: "flex-wrap",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 5),
    borderRadius: 30,
    flexGrow: 1,
    overflow: "hidden",
    minHeight: "100%",
    height: "80vh",
  },
  paperMobile: {
    background:
        "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    display: "flex-wrap",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3, 1),
    borderRadius: 30,
    flexGrow: 1,
    overflow: "hidden",
    minHeight: "100%",
    height: "80vh",
  },
  mobileDivider: {
    width: "80%",
    marginTop: "5%",
    marginBottom: "5%",
    backgroundColor: "white",
  },
  samaggiTextOrange: {
    fontSize: "4.5em",
    color: "#d7a02a",
    lineHeight: "1",
    fontFamily: "gotham-bold",
  },
  samaggiTextTransparent: {
    fontSize: "4.5em",
    WebkitTextStrokeColor: "#d7a02a",
    WebkitTextStrokeWidth: "1px",
    color: "rgba(0, 0, 0, 0.2)",
    lineHeight: "1",
  },
  cssLabel: {
    color: "white"
  },
  menuPaper: {
    maxHeight: "108px",
  },
  cssFocused: {}
}));

export const CardForm: React.VFC<{ loading, setLoading}> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();
  let setOpenLoader = props.setLoading
  const [submit, setSubmit] = useState(true);

  const validateEmail = (email: string, emailReconfirm: string) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    if (!expression.test(String(email).toLowerCase())) {
      alert("The email is in an invalid format");
      return false;
    } else if (email !== emailReconfirm) {
      alert("The emails don't match, please enter again");
      return false;
    } else {
      return true;
    }
  };

  const validateCardNum = (cardNum: string) => {
    const luhn = require("luhn");
    console.log(cardNum.replace(/\s+/g, ""));
    const is_valid = luhn.validate(cardNum.replace(/\s+/g, ""));

    if (!is_valid) {
      alert("Invalid card number");
      return false;
    } else {
      return true;
    }
  };

  async function onConfirm(values, submitting) {
    setOpenLoader(true);
    setSubmit(false);
    axios({
      url: process.env.REACT_APP_API_URL,
      method: "post",
      data: {
        query: `
        query stripePaymentProd {
          stripePaymentProd(email: "${values.email}",price: ${parseFloat(
          localStorage.getItem("totalPrice")
        ).toFixed(2)}, 
            sessionId: "${localStorage.getItem("sessionId")}",
            card: {cardNumber: "${values.cardNo.toString()}", cvc: "${
          values.securityCode
        }", expYear: ${values.expiryYear}, expMonth: ${
          values.expiryMonth
        }}, billing: {name: "${values.name}", fullAddress:"${
          values.addr1 + " " + values.addr2 + " " + values.addr3 + " " + values.city + " " + values.country
        }", postalCode:"${values.postalCode}"}) {
              message
              status
              url
              transactionId
          }
        }
        `,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((result) => {
        submitting(false);
        setSubmit(true);
        console.log(result);
        if (result.data.data.stripePaymentProd != null && (result.data.data.stripePaymentProd.status === 200 || result.data.data.stripePaymentProd.status === 300)) {
          if (result.data.data.stripePaymentProd.message == "requires_action") {
            setOpenLoader(false);
            console.log(result.data.data.stripePaymentProd.transactionId);
            localStorage.setItem(
              "transactionId",
              result.data.data.stripePaymentProd.transactionId
            );
            localStorage.setItem(
              "urlIFrame",
              result.data.data.stripePaymentProd.url
            );
            history.push("./card-authorisation");
          } else {
            setOpenLoader(false);
            history.push("./payment-status");
          }
        } else {
          if (result.data.data.stripePaymentProd && result.data.data.stripePaymentProd.message) {
            alert(result.data.data.stripePaymentProd.message);
          } else {
            alert("Payment failed, please try again");
          }
          setSubmit(true);
          setOpenLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  return (
  <Grid container direction={"column"} spacing={1}>
    <Grid item container direction={"column"} spacing={1}>
      <Grid item>
        <BackButton onClick={() => history.push("./")}/>
        <MenuHeader withEventName/>
      </Grid>
    </Grid>
    <Grid item>
      <Formik
        initialValues={{
          cardNo: "", name: "", expiryMonth: "", expiryYear: "", securityCode: "", email: "", email2: "", addr1: "", addr2: "", addr3: "", city: "", postalCode: "", country: "", agree: false,
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values.cardNo)
          if (
            validateEmail(values.email, values.email2) &&
            validateCardNum(values.cardNo)
          ) {
            onConfirm(values, setSubmitting).then(() => {});
          }
        }}
      >
        {(_) => (
          <Form style={{width: "full"}} autoComplete="on">
            <Box display="flex" flexDirection="column" alignItems="center" style={{ width: !isMobile ? "full" : "100%" }}>
              <Box display="flex" flexDirection="column" alignContent="center" width="100%">
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <Typography variant="h5" style={{ color: "#293c7e", fontWeight: "bold" }}>
                    PAYMENT BY CARD
                  </Typography>

                  <Typography variant="subtitle1" style={{ color: "#5d6a8d", fontWeight: "bold" }}>
                    Total Due {localStorage.getItem("currency")}{parseFloat(localStorage.getItem("totalPrice")).toFixed(2)}
                  </Typography>

                  <Divider style={{width: "60%", backgroundColor: "#404c77", margin: "10px"}}/>
                </Box>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item xs={12}>
                    <SamaggiField label={"Card Number"} name={"cardNo"} placeholder={"Enter your card number"} type={"text"} autoComplete={"cc-number"}/>
                  </Grid>
                  <Grid item container direction={"row"} spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Field
                          required
                          select
                          name="expiryMonth"
                          label="Exp. Month"
                          autocomplete="cc-exp-month"
                          x-autocompletetype="cc-exp-month"
                          component={TextField}
                          style = {{ textAlign: "center", width: "100%" }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                            style: {
                              color: '#293c7e'
                            }
                          }}
                          InputProps={{
                            style: {
                              color: "#293c7e"
                            }
                          }}
                      >
                        {months.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item  xs={12} sm={4}>
                      <Field
                          required
                          select
                          name="expiryYear"
                          label="Exp. Year"
                          variant="outlined"
                          autocomplete="cc-exp-year"
                          x-autocompletetype="cc-exp-year"
                          component={TextField}
                          style = {{ textAlign: "center", width: "100%" }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                            style: {
                              color: '#293c7e'
                            }
                          }}
                          InputProps={{
                            style: {
                              color: "#293c7e",
                              marginBottom: "0"
                            }
                          }}
                      >
                        {years.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SamaggiField label={"CVV"} name={"securityCode"} placeholder={"CVV"} type={"text"} autoComplete={"cc-csc"}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <SamaggiField label={"Full Name"} name={"name"} placeholder={"Name as shown on card"} type={"name"} autoComplete={"on"}/>
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" flexDirection="column" alignContent="center" width="100%">
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={"10%"} marginBottom="10px">
                  <Typography variant="h6" style={{ color: "#293c7e", fontWeight: "bold" }}>
                    BILLING ADDRESS
                  </Typography>
                  <Divider
                    style={{
                      width: "60%",
                      backgroundColor: "#465381",
                    }}
                  />
                </Box>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <SamaggiField label={"Address Line 1"} name={"addr1"} placeholder={"Address Line 1"} type={"text"}/>
                  </Grid>
                  <Grid item>
                    <SamaggiField label={"Address Line 2"} name={"addr2"} placeholder={"Address Line 2"} type={"text"} required={false}/>
                  </Grid>
                  <Grid item>
                    <SamaggiField label={"Address Line 3"} name={"addr3"} placeholder={"Address Line 3"} type={"text"} required={false}/>
                  </Grid>
                  <Grid item container direction="row" spacing={1}>
                    <Grid item xs={6}>
                      <SamaggiField label={"City"} name={"city"} placeholder={"City"} type={"text"}/>
                    </Grid>
                    <Grid item xs={6}>
                      <SamaggiField label={"Postal Code"} name={"postalCode"} placeholder={"Enter Postal Code"} type={"text"}/>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <SamaggiField label={"Country"} name={"country"} placeholder={"Country"} type={"text"}/>
                  </Grid>
                  <Grid item>
                    <SamaggiField label={"Email"} name={"email"} placeholder={"Enter Your Email"} type={"email"}/>
                  </Grid>
                  <Grid item>
                    <SamaggiField label={"Re-confirm your email"} name={"email2"} placeholder={"Re-enter your email"} type={"text"}/>
                  </Grid>
                </Grid>
              </Box>

              <NormalButton thin disabled={!submit} style={{ marginTop: "20px"}}>
                <Typography
                  variant="subtitle2"
                  style={{ color: "#293c7e" }}
                >
                  PAY NOW
                </Typography>
              </NormalButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  </Grid>
  );
};
