import React from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import GothamBold from "../../fonts/Gotham-Bold.woff2";
import {NormalButton} from "../Components/NormalButton";

export const CardAuthorisation: React.VFC<{ loading, setLoading }> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const iframeHeight = isMobile ? "400" : "600";
  const iframeWidth = isMobile ? "100%": "100%"
  const history = useHistory();
  let setOpenLoader = props.setLoading;
  setOpenLoader(false);

  async function onClose() {
    setOpenLoader(true);
    axios({
      url: process.env.REACT_APP_API_URL,
      method: "post",
      data: {
        query: `
        query Check_intent {
          check_intent (sessionId: "${localStorage.getItem(
            "sessionId"
          )}", transactionId: "${localStorage.getItem("transactionId")}"){
            message
            status
          }
         }
        `,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((result) => {
        console.log(result);
        if (
            result.data.data.check_intent !== null &&
            result.data.data.check_intent.status &&
            result.data.data.check_intent.status === 200
        ) {
          //successful 200 goes to page cf
          setOpenLoader(false);
          history.push("./payment-status");
        } else if (
            result.data.data.check_intent !== null &&
            result.data.data.check_intent.status &&
            result.data.data.check_intent.status === 300
        ) {
          setOpenLoader(false);
          history.push("./payment-card");
        } else if (result.data.data.check_intent !== null) {
          //unsuccessful 400 goes back to card payment page
          alert(result.data.data.check_intent.message);
          setOpenLoader(false);
          history.push("/payment-card");
        } else {
          alert("ERROR: Unable to process payment. 3D Authentication Failed.");
          setOpenLoader(false);
          history.push("./payment-card");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <Grid direction="column" spacing={8}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column">
            <Typography component="h4" variant="h4" style={{color: '#293c7e', width: "full", fontFamily: GothamBold, fontWeight: "700"}}>
              3D Authentication Required
            </Typography>
            <Typography component="h6" variant="h6" style={{color: '#293c7e', width: "full", fontFamily: GothamBold, marginBottom: "15px"}}>
              Please verify the payment in the window below.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <iframe
              src={localStorage.getItem("urlIFrame")}
              height={iframeHeight}
              width={iframeWidth}
              style={{marginTop: "10px", marginBottom: "10px", backgroundColor: "white", marginLeft: "auto", marginRight: "auto"}}
          ></iframe>
        </Grid>
        <Grid item xs={12} >
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="subtitle1" style={{ color: '#293c7e', textAlign: "center" }}>
                DO NOT click "I HAVE VERIFIED" until you're told to do so after you have completed your 3D authentication
              </Typography>
            </Grid>
            <Grid item>
              <NormalButton thin onClick={() => onClose()}>
                <Typography variant="subtitle2" style={{ color: "#293c7e" }}>
                  I Have Verified
                </Typography>
              </NormalButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
