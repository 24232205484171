import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import React from "react";

export function BackButton(props: { onClick: () => void }) {
    return <Box display="flex" justifyContent="flex-start">
        <Button onClick={props.onClick}>
            <ArrowBackIcon style={{color: "#293c7e"}}></ArrowBackIcon>
            <Typography variant="subtitle2" style={{color: "#293c7e"}}>
                Back to home
            </Typography>
        </Button>
    </Box>;
}