import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Box,
  makeStyles,
  Divider,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import {MenuHeader} from "../Components/MenuHeader";
import {NormalButton} from "../Components/NormalButton";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 40,
    padding: theme.spacing(1.5),
    backgroundColor: "white",
  },
  paper: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    display: "flex-wrap",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(5, 3),
    marginTop: "auto",
    marginBottom: "auto",
    borderRadius: 30,
    height: "fit-content"
  },
  paperMobile: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    display: "flex-wrap",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3, 1),
    margin: theme.spacing(2, 0),
    borderRadius: 30,
  },
  mobileDivider: {
    width: "80%",
    marginTop: "5%",
    marginBottom: "5%",
    backgroundColor: "#47537a",
  },
  samaggiTextOrange: {
    fontSize: "4.5em",
    color: "#d7a02a",
    lineHeight: "1",
    fontFamily: "gotham-bold",
  },
  samaggiTextTransparent: {
    fontSize: "4.5em",
    WebkitTextStrokeColor: "#d7a02a",
    WebkitTextStrokeWidth: "1px",
    color: "rgba(0, 0, 0, 0.2)",
    lineHeight: "1",
  },
  cssLabel: {
    color: "white",
  },
  cssFocused: {},
}));

export const PaymentStatus: React.VFC<{ loading, setLoading }> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let setOpenLoader = props.setLoading;

  const [data, setData] = useState(undefined);
  const [msg, setMsg] = useState("");

  async function getPaymentStatus() {
    setOpenLoader(true);
    axios({
      url: process.env.REACT_APP_API_URL,
      method: "post",
      data: {
        query: `
        query Confirm {
          confirm(sessionId: "${localStorage.getItem("sessionId")}") {
            message
            orderId
            paymentStatus
            status
          }
         }
        `,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((result) => {
        setData(result.data.data.confirm);
        if (result.data.data.confirm.paymentStatus === "TRANSFER_PENDING") {
          setMsg("Transfer in confirmation process");
        } else if (result.data.data.confirm.paymentStatus === "NO_PAYMENT") {
          setMsg("Payment incomplete due to error");
        } else {
          setMsg("Payment completed");
        }
        setOpenLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getPaymentStatus();
  }, []);

  console.log(data);

  if (data) {
    return (
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          alignItems="center"
        >
          <MenuHeader withEventName withDate/>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            marginTop="5%"
          >
            <Typography variant="h6" style={{ color: "#293c7e", fontWeight: "700" }}>
              ORDER NO: {data.orderId}
            </Typography>
            <Typography variant="subtitle1" style={{ color: "#293c7e", width: "85%", textAlign: "center" }}>
              You should soon receive your confirmation email. You can check the order status with your order number.
            </Typography>
            <Divider className={classes.mobileDivider} />
            <Typography
              component="h5"
              variant="h5"
              style={{ color: "#293c7e", margin: "2px", fontWeight: "700" }}
            >
              {msg}
            </Typography>
            <Typography
              component="h5"
              variant="h5"
              style={{ color: "#293c7e", margin: "2px", fontWeight: "700" }}
            >
              Thank You for Your Purchase
            </Typography>
            <Typography variant="subtitle1" style={{ color: "#293c7e", width: "85%", textAlign: "center" }}>
              Your ticket is being processed, you will receive an email within 24 hours. If not please check your spam box.
            </Typography>
          </Box>
          <NormalButton thin onClick={() => {history.push("./");}} style={{marginTop: "20px"}}>
            <Typography variant="subtitle2" style={{ color: "#293c7e" }}>
              RETURN TO MAIN MENU
            </Typography>
          </NormalButton>
        </Box>
      </div>
    );
  } else {
    return (
      <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};
